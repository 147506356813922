import 'jquery-ui/ui/widgets/tabs.js'
const $distributors = $('.distributors');

if ($distributors.length) {
  $distributors.tabs({
    active: 0
  });

  $('.search-distributors').on('input', function() {
    let searchValue = $(this).val().toLowerCase();
    let $distGroups = $('.dist-group')

    $distGroups.each(function() {
      let $distGroup = $(this);
      let title = $distGroup.find('.dist-group__title').text().toLowerCase();

      if (title.includes(searchValue)) {
        $distGroup.show();
      } else {
        $distGroup.hide();
      }
    });
  });

  $('.distributors-select').on('change', function() {
    let selectedValue = $(this).val().toLowerCase();
    let $distGroups = $('.dist-group');

    $distGroups.each(function() {
      let $distGroup = $(this);
      let title = $distGroup.find('.dist-group__title').text().toLowerCase();

      if (selectedValue === "" || title.includes(selectedValue)) {
        $distGroup.show();
      } else {
        $distGroup.hide();
      }
    });
  });

  // Clear button functionality
  $('#reset-international').on('click', function() {
    let $input = $(this).closest('.filter-group').find('input[data-jplist-control="textbox-filter"]');
    let $select = $(this).closest('.dist-filters').find('.distributors-select');

    $input.val('');
    $input.trigger('input');

    $select.val('');
    $select.trigger('change');
  });
}
