$(document).on('click', '.quantity-stepper', (event) => {
  const $this = $(event.currentTarget);
  const $input = $this.parent().find('input');
  const increment = Number($this.data('increment'));

  // We have to get min and max on click bc these values change on variation select
  const min = Number($input.attr('min'));
  const max = Number($input.attr('max'));
  const step = Number($input.attr('step'));
  const initialValue = Number($input.val());

  if (max) {
    $input.val(Math.min(max, Math.max(min, initialValue + (step * increment))));
  } else {
    $input.val(Math.max(min, initialValue + (step * increment)));
  }

  $input.trigger('change');
});

window.onload = function() {
  if ($('.flex-control-thumbs').length) {
    $('.single-product-image-container').css('margin-left', '100px');
  }
  if ($('.product-cta-container').length) {
    $('.woocommerce-product-gallery').css('padding-bottom', '90px');
  }
};
