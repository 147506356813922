document.addEventListener('DOMContentLoaded', function() {
  const lightbox = document.getElementById('lightbox');
  const lightboxVideo = document.getElementById('lightbox-video');
  const closeBtn = document.querySelector('.close');

  if(lightbox) {
    document.querySelectorAll('.cat_video_card').forEach(card => {
      card.addEventListener('click', function(event) {
        event.preventDefault();
        const videoSrc = this.getAttribute('data-video');
        lightboxVideo.src = videoSrc + '?autoplay=1';
        lightbox.style.display = 'flex';
        document.body.classList.add('no-scroll');
        document.documentElement.classList.add('no-scroll');
      });
    });

    closeBtn.addEventListener('click', function() {
      lightbox.style.display = 'none';
      lightboxVideo.src = '';
      document.body.classList.remove('no-scroll');
      document.documentElement.classList.remove('no-scroll');
    });

    window.addEventListener('click', function(event) {
      if (event.target === lightbox) {
        lightbox.style.display = 'none';
        lightboxVideo.src = '';
        document.body.classList.remove('no-scroll');
        document.documentElement.classList.remove('no-scroll');
      }
    });
  }
});
