const $input = $('input, textarea');
const $wrapper = $('.product-catalog-group');

$input.on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('away');

  console.log($(this).attr('type'));
  console.log($(this).val().length);
});

$input.on('blur', function () {
  if (!$(this).val()) {
    $(this).closest('.gfield').find('label').removeClass('away');
  }
});

$('input[type="file"]').on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('focus');
});
$('input[type="file"]').on('blur', function () {
  $(this).closest('.gfield').find('label').removeClass('focus');
});

$('footer .gfield--type-html').on('click', ()=> {
  $('.gform_footer .gform_button').click()
})

$(document).on('select2:open', 'select', function() {
  $('.select2-search--dropdown .select2-search__field').attr('placeholder', 'Type to search…');
});

$wrapper.on('init change', '.woocommerce-ordering select', ({currentTarget: element}) => {
  $(element).select2({minimumResultsForSearch: Infinity}).on('select2:open', () => $('.select2-dropdown').addClass('max-content'));
  const text = $(element).next().text();
  $('.woocommerce-ordering .select2-selection__rendered').each((i, el) => {
    $(el).html(`<span>${text.replace(/(sort by )?(.+)/i, '$1<b>$2</b>')}</span>`);
  });
});

$('.woocommerce-ordering select', $wrapper).trigger('init');

$wrapper.on('change submit', '.woocommerce-ordering', event => {
  event.preventDefault();
  const $filter = $('.filter', $wrapper);
  $(event.currentTarget).serializeArray().forEach(({name, value}) => {
    $filter.append(`<input type="hidden" name="${name}" value="${value}">`);
  });
  $filter.trigger('submit');
});
