const $body = $('html, body');
const $wrapper = $('.product-catalog-group');

$wrapper.on('click', '.sort-pagination__filter, .filter-close', () => $body.toggleClass('show-sidebar'));
$wrapper.on('click', '#filter-button, .apply-filters, .close-button', () => $body.removeClass('show-sidebar'));
$(window).on('resize', ({currentTarget: element}) => $(element).width() > 1024 && $body.removeClass('show-sidebar'));

$wrapper.on('change', ':radio, :checkbox', ({currentTarget: target}) => {
  const group = target.closest('.wpfFilterWrapper');
  const {name, queryType} = group.dataset;
  if (name) {
    const values = [...group.querySelectorAll(':checked')].map(x => x.dataset.value);
    group.querySelector(`input[name="filter_${name}"]`).value = values.join(',');
    if (queryType) group.querySelector(`input[name="query_type_${name}"]`).value = values.length ? queryType : '';
  }
  if ($(window).width() > 1024) {
    ajax && ajax.abort();
    clearTimeout(timeout);
    timeout = setTimeout(() => $(target).trigger('submit'), 1000);
  }
  $wrapper.trigger('recount');
});

$wrapper.on('click', '.remove-filter', ({currentTarget: element}) => {
  const [name, value] = [element.parentElement.dataset.name, element.dataset.value];
  if (name === 'price') {
    $('input[name="min_price"], input[name="max_price"]', $wrapper).val('');
    $('input[name="price_preset"]', $wrapper).prop('checked', false);
    $(element.parentElement).slideUp().trigger('submit');
  } else if (name === 'size') {
    $('input[name^="size"]', $wrapper).remove();
    $(element.parentElement).slideUp().trigger('submit');
  } else {
    $(`input[data-name="${name}"][data-value="${value}"]`, $wrapper).prop('checked', false).trigger('change');
    $(`input[data-name="${name}"]:checked`, $wrapper).length ? $(element).slideUp() : $(element.parentElement).slideUp();
  }
  $wrapper.trigger('recount');
});

$wrapper.on('recount', event => {
  let count = $(':radio:checked, :checkbox:checked', $wrapper).not('[name=price_preset]').length;
  count += $('[name=min_price]', $wrapper).val() > 0 ? 1 : 0;
  count += $('[name=max_price]', $wrapper).val() > 0 ? 1 : 0;
  count += $('[name^="size"]', $wrapper).val() ? 1 : 0;
  $('.filter-count', $wrapper).text(count);
}).trigger('recount');
