import Swiper from 'swiper';
import {Autoplay, Mousewheel, Navigation, Scrollbar} from 'swiper/modules';

Swiper.use([Autoplay, Mousewheel, Navigation, Scrollbar]);

document.querySelectorAll('.wc-prl-recommendations').forEach(el => {
  const controls = document.createElement('div');
  controls.classList.add('swiper-scrollbar');
  el.appendChild(controls);

  new Swiper(el, {
    createElements: true,
    wrapperClass: 'products',
    slideClass: 'product',
    slidesPerView: 'auto',
    mousewheel: {
      forceToAxis: true,
    },
    scrollbar: {
      draggable: true,
      enabled: true,
      el: controls,
    },
    on: {
      init: swiper => {
      },
    },
    lock: swiper => {
      controls.classList.add('swiper-scrollbar-lock');
    },
    unlock: swiper => {
      controls.classList.remove('swiper-scrollbar-lock');
    },
  });
});
