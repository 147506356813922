import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const categorySlider  = new Swiper('.category-swiper',{
  direction: 'horizontal',
  slidesPerView: 2,
  autoHeight: false,
  loop: true,
  spaceBetween: 20,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
  navigation:{
    nextEl: ".cat-swiper-button-next",
    prevEl: ".cat-swiper-button-prev",
    disabledClass: 'disabled_swiper_button'
  },
  breakpoints: {
    501: {
      slidesPerView: 2,
    },
    800: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1250: {
      slidesPerView: 5,
    },
    1500: {
      slidesPerView: 6,
    }
  }
})
