const $tooltip = $('.reset-selections-tooltip');
const Money = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

class Variation {
  constructor($form) {
    this.$form = $form;

    $form.on('found_variation', this.#found_variation.bind(this));
    $form.on('hide_variation', this.#hide_variation.bind(this));
    $form.on('check_variations', this.#check_variations.bind(this));
    $form.on('change', this.#calculate_subtotal.bind(this));
    $form.find('.variable-item').on('click', this.#variable_item_click.bind(this));
    $form.find('.reset_variations').on('click', this.#reset_variations.bind(this));

    const $wrap = $form.parents().eq(2);
    this.$price = $wrap.find('.wc-block-components-product-price, .price');
    this.$stock = $wrap.find('.stock_wrap');
    this.$sku = $wrap.find('.sku');
    this.$subtotal = $wrap.find('.woocommerce-variation-subtotal');

    this.price_html = this.$price.html();
    this.stock_html = this.$stock.html();
  }

  static init(event) {
    let $form = $('.variations_form:last');
    if (event?.handleObj.type === 'woosq_loaded') {
      $form = $('.woosq-popup .variations_form');
    }
    if ($form.length !== 0) new Variation($form);
  }

  #found_variation(event, variation) {
    this.current_variation = variation;
    this.$price?.html(variation.price_html || this.price_html);
    this.$stock?.html(variation.availability_html || this.stock_html);
    if (this.$sku.siblings().length) {
      this.$sku?.html(variation.sku).prepend('SKU: ').show();
    } else {
      variation.sku ? this.$sku?.html(`SKU: ${variation.sku}`).slideDown() : this.$sku?.slideUp(400, () => this.$sku?.html(' '));
    }
    this.#calculate_subtotal();
  }

  #check_variations() {
    cancelAnimationFrame(this.animationFrame);

    const variationAttributes = this.get_product_attributes();

    const $variableItems = this.$form.find('ul.variable-items-wrapper');

    const attributes = {};
    this.$form.find('select').each(function () {
      const attribute_name = $(this).data('attribute_name') || $(this).attr('name');
      attributes[attribute_name] = $(this).val() || '';
    });

    this.animationFrame = requestAnimationFrame(() => {

      $variableItems.find('li').addClass('disabled');
      $variableItems.siblings('select').find('option[value!=""]');

      $variableItems.each((i, ul) => {
        const $input = $(ul).siblings('select');
        const {attribute_name} = ul.dataset;

        $(ul).find('li:visible').each((i, li) => {
          const $this = $(li);
          const variation = {...attributes, [attribute_name]: li.dataset.value};

          const found = variationAttributes.some(variation1 => {
            for (const [attribute_name1, val1] of Object.entries(variation1)) {
              const val2 = variation[attribute_name1];
              if (val1 !== undefined && val2 !== undefined && val1.length !== 0 && val2.length !== 0 && val1 !== val2) {
                return false;
              }
            }
            return true;
          });

          const $option = $input.find(`option[value="${li.dataset.value}"]`);
          if (found) {
            $this.removeClass('disabled');
            $option.prop('disabled', false);
          } else {
            $this.addClass('disabled');
            $option.prop('disabled', true);
          }
        });

        // Click on any solo option available
        const $item = $(ul).find('li:not(.disabled)').filter(':visible');
        if ($item.length === 1 && !$item.hasClass('selected')) $item.click();
      });
    });
  }

  #variable_item_click(event) {
    const $this = $(event.currentTarget);

    if ($this.hasClass('not-allowed')) {
      event.stopPropagation();
    } else if ($this.hasClass('selected')) {
      const $siblings = $this.siblings('li:not(.disabled)');
      $siblings.length || event.stopPropagation();
    } else if ($this.hasClass('disabled')) {
      const $siblings = $this.siblings('li:visible');
      if ($siblings.length) {
        $this.removeClass('disabled');
        const $variableItems = this.$form.find('ul.variable-items-wrapper');
        $variableItems.find('li').removeClass('selected').attr('aria-checked', 'false').attr('tabindex', '-1');
        $variableItems.siblings('select').find('option[value!=""]').prop('disabled', false).prop('selected', false);
      } else {
        event.stopPropagation();
      }
    }
  }

  #variable_item_reset() {
    const $variableItems = this.$form.find('ul.variable-items-wrapper');
    $variableItems.find('li').removeClass('selected').attr('aria-checked', 'false').attr('tabindex', '-1');
    $variableItems.siblings('select').find('option[value!=""]').prop('selected', false);
  }

  #reset_variations(event) {
    this.#variable_item_reset();
    $(event.currentTarget).slideUp();
  }

  get_product_attributes() {
    return this.$form.data('product_attributes');
  }

  #hide_variation() {
    this.current_variation = null;
    this.$price?.html(this.price_html);
    this.$stock?.html(this.stock_html);
    this.$sku?.slideUp(400, () => this.$sku?.html(''));
    this.#calculate_subtotal();
  }

  #calculate_subtotal() {
    let subtotal = this.current_variation?.display_price * this.$form.find('[name=quantity]').val();
    const subtotal_html = subtotal > 0 ? '<label>Subtotal:</label>' + Money.format(subtotal) : null;
    const stock_html = subtotal > 0 && this.current_variation?.is_in_stock ? ' <span>|</span> <span class="in-stock">In Stock</span>' : ' <span>|</span> <span class="out-of-stock">Out of Stock</span>';
    const final_html = subtotal_html ? subtotal_html + stock_html : null;
    final_html ? this.$subtotal?.html(final_html).slideDown() : this.$subtotal?.slideUp(400, () => this.$subtotal?.html(''));
  }
}

Variation.init();
$(document.body).on('woosq_loaded', Variation.init);

$(document).on('change', '.variations_form .variations select', event => {
  $(event.target).removeClass('required').next().removeClass('required');
});

$(document).on('click', '.variations_form .single_add_to_cart_button', event => {
  const $this = $(event.target);
  const $form = $this.closest('form.variations_form');
  if ($this.is('.wc-variation-selection-needed')) {
    $form.find('select').each((i, select) => {
      const $select = $(select);
      if (!$select.val()) $select.addClass('required').next().addClass('required');
    });
  }
});

// single product  subtotal
$('.product-type-simple .qty').on('change', function() {
  const $this = $(this);
  const $form = $('form.cart');
  const $price = $form.find('.single-price');
  const quantity = $this.val();
  const price = $price.data('price');
  const subtotal = quantity * price;
  $form.find('.single-price').html(Money.format(subtotal));
} );
